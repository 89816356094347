import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MyDietCommonService } from '../../services/mydiet-common.service';
import { SignupModel } from '../../models/signup.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  //signupForm!: FormGroup;
  formSubmit: boolean = false;
  successMsg: any = '';
  errorMsg: any = '';
  firstName: string = '';
  lastName: string = '';
  emailId: any;
  confirmEmail: any;
  isValidEmail = false;
  emailErrMsg: any;
  email2ErrMsg: any;
  unitNoErrMsg: any;
  phnErrMsg: any;
  isValidEmailId = false;
  invalidFname: boolean = false;
  invalidLastName: boolean = false;
  phone: string = '';
  phoneValue: string ='';
  phNumber: any;
  validPhNo: boolean = false;
  SignupReqUI: any;

  signupForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    emailId: new FormControl(),
    emailId2: new FormControl(),
    phone: new FormControl(),
    compassFacilityUnitNo: new FormControl(),
    compassFacilityUnitName: new FormControl()
  })

  @BlockUI('signup-data-details') blockUI: NgBlockUI;

  constructor(
    private toastrService: ToastrService,
    private mydietCommonService: MyDietCommonService,
    private router: Router) { }

  ngOnInit(): void {


  }

  backBtn() {
    this.router.navigate(['/selfService'])
   }

  emptyField() {
    this.successMsg = '';
    this.errorMsg = '';
  }

  //firstName validation
  validFirstName(event: any) {
    this.emptyField();
    let fName = event.target.value;
    let pattern = /^\s+$/;
    if (fName.length > 0 && pattern.test(fName)) {
      this.invalidFname = true;
    } else {
      this.invalidFname = false;
    }
  }
  //lastName validation
  validLasttName(event: any) {
    this.emptyField();
    let lName = event.target.value;
    let pattern = /^\s+$/;
    if (lName.length > 0 && pattern.test(lName)) {
      this.invalidLastName = true;
    } else {
      this.invalidLastName = false;
    }
  }

  compareEmail(): void {

    this.emailId = this.signupForm.get('emailId')?.value;
    this.confirmEmail = this.signupForm.get('emailId2')?.value
    if ((this.emailId != '' && this.confirmEmail != '') && (this.emailId != null && this.confirmEmail != null)) {
      if (this.confirmEmail.toLocaleLowerCase().trim() === this.emailId.toLocaleLowerCase().trim()) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    }
    else {
      this.isValidEmail = true;
    }
    this.signupForm.get('emailId2')?.setErrors(null);
    if(this.isValidEmail){
      this.email2ErrMsg = "";
    }else{
      let emailVerificationErrMsg = {
        emailValidation: {
          errorMsg: "The email addresses entered do not match."
        }
      }
      this.signupForm.get('emailId2')?.setErrors(emailVerificationErrMsg);
      this.email2ErrMsg = "The email addresses entered do not match."
    }

    this.signupForm.get('emailId')?.setErrors(null);
    let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,25})+$/;

    if (pattern.test(this.emailId.trim())) {
      this.emailErrMsg = "";
      this.isValidEmailId = true;
    } else {
      let emailValidationErrMsg = {
        emailValidation: {
          errorMsg: "This field must be a valid email address format."
        }
      }
      this.isValidEmailId = false;
      this.signupForm.get('emailId')?.setErrors(emailValidationErrMsg);
      this.emailErrMsg = "This field must be a valid email address format."
    }

  }

  phoneFormat(event: any) {
    this.emptyField();
    let pattern1 = /^[0-9]{10}$/;
    let pattern2 = /^[(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/
    let pattern3 = /^\d{3}-\d{3}-\d{4}$/;
    let num = event.target.value;
    this.phoneValue = num;
    if (pattern1.test(num)) {
      this.validPhNo = true
      let num1 = num.slice(0, 3);
      let num2 = num.slice(3, 6);
      let num3 = num.slice(6, 10);
      this.signupForm.patchValue({
        phone: "(" + num1 + ") " + num2 + "-" + num3
      })
      this.numberFormat();
    } else if (pattern3.test(num)) {
      this.validPhNo = true
      let numSplit = num.split('-')
      let num1 = numSplit[0];
      let num2 = numSplit[1];
      let num3 = numSplit[2];
      this.signupForm.patchValue({
        phone: "(" + num1 + ") " + num2 + "-" + num3
      })
      this.numberFormat();
    } else if (pattern2.test(num)) {
      this.validPhNo = true
      this.numberFormat();
    }
    else {
      this.validPhNo = false
    }
  }

  numberFormat() {
    this.phNumber = this.signupForm.get('phone')?.value;
    let phNumbers = this.phNumber.split(/[(]|[\s]|[)]|[-]/);
    this.phNumber = (phNumbers[1] + phNumbers[3] + phNumbers[4]);
    this.phoneValue = this.phNumber;
  }

  validatePhone(c: any) {
    
    let phoneNum = this.signupForm.get('phone')?.value;
    if((phoneNum === null) || (phoneNum === "")){
      this.validPhNo = true;
    }
    //let phnErrMsg ="";
    if(this.validPhNo){
      this.signupForm.get('phone')?.setErrors(null);
    }else{
      let pattern = /^[0-9]{10}$/;
      let value = c.target.value;
  
      if (pattern.test(value)) {
        this.phnErrMsg = "";
        //this.isValidEmailId = true;
      } else {
        let phoneValidationErrMsg = {
          phoneValidation: {
            errorMsg: "This field must be a 10 digit phone number"
          }
        }
        this.signupForm.get('phone')?.setErrors(phoneValidationErrMsg);
        this.phnErrMsg = "This field must be a 10 digit phone number."
  
      }
  
    }
    
  }

  keyFiveChar(e: any) {
    this.emptyField();
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }


  getCostCenter(data: any) {
    let costCenterNum = '';
    let ccNum = data.target.value;
    const pattern = /[0-9]/;
    this.signupForm.get('compassFacilityUnitNo')?.setErrors(null);
    
    if (pattern.test(ccNum)) {
      this.unitNoErrMsg = "";
      if (ccNum !== '' && ccNum.length < 5) {
        costCenterNum = ccNum.padStart(5, '0');
      } else {
        costCenterNum = ccNum;
      }
      this.signupForm.patchValue({
        compassFacilityUnitNo: costCenterNum
      })
    }else{
      let unitNoVerificationErrMsg = {
        unitValidation: {
          errorMsg: "This field must be numeric"
        }
      }
      this.signupForm.get('compassFacilityUnitNo')?.setErrors(unitNoVerificationErrMsg);
      this.unitNoErrMsg = "This field must be numeric";
    }  
  }

  createSubmitObject() {
    let obj = {
      firstname: this.signupForm.get('firstName')?.value ? (this.signupForm.get('firstName')?.value)?.trim() : this.firstName,
      lastname: this.signupForm.get('lastName')?.value ? (this.signupForm.get('lastName')?.value)?.trim() : this.lastName,
      email: this.signupForm.get('emailId')?.value ? (this.signupForm.get('emailId')?.value)?.trim().toLocaleLowerCase() : this.emailId,
      phoneNumber: this.signupForm.get('phone')?.value ? this.phoneValue : this.phone,
      costCenterNumber: this.signupForm.get('compassFacilityUnitNo')?.value ? this.signupForm.get('compassFacilityUnitNo')?.value : "",
      aliasCostCenter: this.signupForm.get('compassFacilityUnitName')?.value ? this.signupForm.get('compassFacilityUnitName')?.value : "",
      sourceType: 1
    }

    return obj;
  }

  onSubmit() {
    this.formSubmit = true;
    this.emptyField();
    if (this.signupForm.valid) {
      this.blockUI.start();
      let body = this.createSubmitObject();
      this.mydietCommonService.submitSignUp(body).subscribe({
        next: (res) => {
          if (res.metaData && res.metaData.status === "Success") {
                  this.toastrService.success('Your request has been successfully submitted and is waiting to be approved. ');
                  this.signupForm.reset();
                  this.formSubmit = false;
                  this.router.navigate(['/selfService']);
                }
                else {
                  this.toastrService.error('Could not submit data.');
                }
                this.blockUI.stop();
        },
        error: (error) => {
          console.error(error);
          this.blockUI.stop();
          this.toastrService.error('Could not submit data.');
        }
      });
    } else {
      this.toastrService.error('Please check if all the required fields are set and no errors.')
    }
  }

}
