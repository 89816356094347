<div class="kt-portlet" style="margin-bottom: 0% !important;">
  <div class="kt-portlet__body" style="padding: 0% !important;">
    <div class="section-header">
      <h2 style="font-size: 2rem !important;font-weight: normal !important;
    color: #70C1C0 !important;" class="styled-first-letter">{{ selectedMenu }}</h2>
      <label (click)="printContent()" class="print-button">
        <i class="fas fa-print print-icon"></i> Print
      </label>
    </div>
    <div class="toc-submenu-name">
      <h3 style="font-size: 20px !important; font-weight: 700; color: #333 !important;">{{ selectedSubMenu }}</h3>
    </div>
    <div class="content" [innerHTML]="content">
      <!-- <iframe #iframe width="100%" height="600px"></iframe> -->
    </div>
  </div>
</div>