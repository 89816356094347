<div class="row" *blockUI="'facility-news'; message: 'Loading';">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-12 col-lg-4 col-md-4">
                <h4 style="margin-top: revert !important;" class="kt-font-prop">Facility News </h4>
            </div>
            <div class="col-sm-4 col-lg-4 col-md-4">
                <ng-select name="sortFacilityNews" [searchable]="false" [clearable]="false"
                    [(ngModel)]="sortFacilityModel" (change)="sortNewsChange()" [items]="sortNewsOptions" required>
                </ng-select>
            </div>
            <div *ngIf="!isUser" style="position: relative; margin-left: auto;" class="col-sm-4 col-lg-4 col-md-4">
                <button type="button" (click)="addFacilityNews()" [disabled]="!isValidRoleAndSubscribed"
                    class="btn btn-outline-primary btn-sm news-btn">
                    <i class="fas fa-plus"></i>
                    Add News</button>
            </div>
        </div>
        <div class="row">
            <app-news-display [isValid]="isValidRoleAndSubscribed" [pageSource]="pageSource"
                (editInitiatedParent)="initiateEdit($event)" [newsData]="facilityNews"></app-news-display>
        </div>
        <div class="row" class="common-no-news-txt" *ngIf="!isFacilityNewsAvailableForFacility">
            <h5 class="subHeader">There is no News available.</h5>
        </div>
    </div>
</div>