import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements ICellRendererAngularComp {

  constructor() { }

  params: any;

  agInit(params: any): void {
    this.params = params;
  }
  
  refresh(params: any): boolean {
    params.data.cbox = params.value
    params.api.refreshCells(params);
    return false;
  }

}
