<div class="modal-header" style="background-color: #70c1c0; font-weight: 600;">
  <h4 class="custom-title">{{title}}</h4>
  <button mat-icon-button type="button" aria-label="Close" (click)="activeModal.dismiss(null)"
    style="border: none; background:transparent;margin:auto; right: 20px; position: absolute;">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="bodyText">
    <div class="col-sm-12" style="word-wrap: break-word;">
      {{ bodyText }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')"
    *ngIf="showCancel">{{cancelText}}</button>&nbsp;&nbsp;
  <button type="button" class="btn btn-primary" (click)="activeModal.close('success')">{{ okText }}</button>
</div>