import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatIconModule } from '@angular/material/icon'
import { BlockUIModule } from 'ng-block-ui';
import { GridModule } from 'stg-js-ngx-grid';
import { GridRenderersModule } from 'src/app/grid-renderers/grid-renderers.module';
import { DirectivesModule } from "../common/directives/directive.module";
import { AddendumsComponent } from "./facility-specific/addendums/addendums.component";
import { AddDocumentModalComponent } from './facility-specific/add-document-modal/add-document-modal.component';
import { ViewDietManualsComponent } from "./diet-manuals/view-diet-manuals/view-diet-manuals.component";
import { ManualsRoutingModule } from "./manuals-routing.module";
import { CustomManualsViewModule } from "../common/components/custom-manual-views/custom-manual-views.module";
import { AddendumsPreviewComponent } from './facility-specific/addendums/addendums-preview/addendums-preview.component';

@NgModule({
    declarations: [
        AddendumsComponent,
        AddDocumentModalComponent,
        ViewDietManualsComponent,
        AddendumsPreviewComponent
    ],
    exports: [
        AddendumsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatIconModule,
        BlockUIModule,
        GridModule,
        GridRenderersModule,
        DirectivesModule,
        ManualsRoutingModule,
        CustomManualsViewModule
    ]

})
export class ManualsModule { }