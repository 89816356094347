import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[myDietOnlyNumbers]'
})
export class MyDietOnlyNumbersDirective {
  private inputElement: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event:any) {
    if (!(/^\d*\.?\d*$/.test(event.key))) {
      event.preventDefault();
    } else {
      return;
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (!/^\d*\.?\d*$/.test(pastedText)) {
      event.preventDefault();
    }
  } 
}
