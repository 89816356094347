<div class="kt-grid kt-grid--hor kt-grid--root" *ngIf="appLoad && !selfService">
    <app-oauth (onOauthLoad)="loadApp()" rootContext="/" [serverless]=true [serverlessConfig]="oauthConfig"></app-oauth>
    <div *ngIf="showRouter" class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <stg-ngx-left-nav class="alterBg" [menuList]="menuItems" [headerLogo]="logo" [minimizeOnLoad]="true"
            howSubMenuOn="hover"></stg-ngx-left-nav>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            <stg-ngx-header [headerLogo]="logo" [profileImageUrl]="profileImageUrl"
                [profileMenuItems]="profileMenuItems">
                <div class="col-sm-6" *ngIf="!disableNotificationIconForOtherPages"
                    style="margin-top: 70%; margin-left: -50%;">
                    <mat-icon class="icon" [matBadge]="badgeCount" matBadgeColor="warn" (click)="showNotificationCount()"
                        [matBadgeHidden]="hidden">
                        notifications
                    </mat-icon>
                </div>
            </stg-ngx-header>
            <app-page-header #pageHeader>
                <app-facility-selector *ngIf="showFacilitySelector" [facilityList]=facilityList>
                </app-facility-selector>
            </app-page-header>
            <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                <div class="kt-container kt-grid__item kt-grid__item--fluid kt-container--fluid">
                    <block-ui>
                        <div *ngIf="loadWithRBAC">
                            <router-outlet></router-outlet>
                        </div>
                    </block-ui>
                </div>
            </div>
            <stg-ngx-footer class="alterBgFooter"></stg-ngx-footer>
        </div>
    </div>

    <div [ngClass]="(path === '/home') ? 'NoMargin' : 'styleMargin'">
        <div class="textStyle" *ngIf="showAuthMsg && !showRouter">
            <div class="loaderAuth"></div>
            <span>Authorization In Progress</span>
            <!-- </div><div *ngIf="showRouter"><router-outlet></router-outlet></div> -->
        </div>

    </div>
</div>
<div *ngIf="appLoad && selfService">
    <div class="bodycontainer bodyContent">
        <div class="row" id="content" [ngClass]='{"pageContent":prelogin}'>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div *ngIf="unauthorized && !appLoad">
    <router-outlet></router-outlet>
</div>