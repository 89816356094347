import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ServerlessOAuthConfig } from '@stg-js-ngx-core/oauth';
import { MyDietCommonService } from './common/services/mydiet-common.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ServerlessOauthService } from "@stg-js-ngx-core/oauth";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonModalComponent } from './common/components/common-modal/common-modal.component';
import { AppConfigService } from './appServiceConfig';
import { MyDietRbacCustomService } from './common/services/mydiet-rbac.service';
import { facilityListAPIResponse, facilityListModel } from './common/models/facilitylist.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public showPopup: boolean = false;
    public title = 'my-diet-manual-ui-app';
    public appLoad: boolean = true;
    public selfService: boolean = true;
    public logo: string = '/assets/images/Nutrition Manual Across the Continuum of Care Final.png';
    public profileImageUrl: string = '/assets/images/genericAvatar.png';
    public showAuthMsg: boolean = false;
    public showRouter: boolean = false;
    public badgeCount = 0;
    public hidden = true;
    public showFacilitySelector: boolean = false;
    // public oauthProfile: OauthProfileResponseObject;
    public disableNotificationIconForOtherPages: boolean = true;
    public showMenu: boolean = false;
    public unauthorized: boolean = false;
    private timerLeft: number = 50;
    private autoSaveInterval: any;
    private selfServiceDisbaleSub: Subscription;

    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('pageHeader') pageHeader: ElementRef;

    menuItems: any = [];
    menuItemsList: any = [
        { title: 'Home', page: '/home', iconClass: 'fas fa-home', stgSecuredObjectCustom: null },
        { title: 'New Facility Registration Approvals ', page: '/approvals', iconClass: 'fas fa-user-plus', stgSecuredObjectCustom: 'User Administration' },
        { title: 'Administration', page: '/administration', iconClass: 'far fa-cog', stgSecuredObjectCustom: 'DIETMANUAL_ADMINISTRATION' },
        { title: 'Diet Manual', page: '/dietmanuals', iconClass: 'fa fa-sharp fa-regular fa-carrot', stgSecuredObjectCustom: 'DIETMANUAL_MANUAL' },
        { title: 'Patient Education', page: '/userpatienteducation', iconClass: 'fas fa-chalkboard-teacher', stgSecuredObjectCustom: 'DIETMANUAL_PATIENTHANDOUTS' },
        { title: 'Calculations', page: '/calculations', iconClass: 'fa fa-regular fa-calculator', stgSecuredObjectCustom: 'DIETMANUAL_CALCULATIONS' },
        { title: 'MyNutrition Resources', page: '/mynutritionresources', iconClass: 'fal fa-books', stgSecuredObjectCustom: 'DIETMANUAL_NUTRITIONRESOURCES' }
    ];

    profileMenuItems: any = [];

    oauthConfig: ServerlessOAuthConfig = {
        serverless: true,
        clientId: this.appConfigService.getConfig().clientId,
        rbacEnabled: true,
        rbacAppName: this.appConfigService.getConfig().rbacAppName,
        revalidateAfterMin: 30,
        sessionExpirationAlertInMin: 3,
        longestTimeToLiveHours: 8,
        autoLogoutOnSessionExpired: true,
        logoutUrl: this.appConfigService.getConfig().baseUrls.logout,
        environmentConfig: this.appConfigService.getConfig()
    };
    path: string;
    prelogin: boolean;
    public loadWithRBAC: boolean = false;
    public facilityList: facilityListModel[] = [];

    constructor(private commonService: MyDietCommonService, private router: Router, private serverlessOauthService: ServerlessOauthService,
        private modalService: NgbModal,
        private myDietCommonService: MyDietCommonService,
        private toastrService: ToastrService,
        private appConfigService: AppConfigService,
        private customRbacService: MyDietRbacCustomService,
        private cookieService: CookieService) {
        const existingToken = this.cookieService.get('host-token');

        if (window.location.href.indexOf('token') > -1 && window.location.href.indexOf('selfService') === -1) {
            this.selfService = false;
            this.appLoad = true;
            this.unauthorized = false;
            this.blockUI.start();
        }
        else if (existingToken) {
            this.selfService = false;
            this.appLoad = true;
            this.unauthorized = false;
            this.commonService.oAuthToken = existingToken;
            this.blockUI.start();
        }

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let url = event.urlAfterRedirects;
                let path = url.split('/');
                path = path[1].split('?');
                if (path && path.length) {
                    if (path[0].indexOf('selfService') === -1 && path[0].indexOf('signup') === -1 && path[0].indexOf('unauthorized') === -1) {
                        this.path = '/' + path[0];
                        this.selfService = false;
                        this.appLoad = true;
                        this.prelogin = true;
                    } else {
                        this.prelogin = false;
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let url = event.urlAfterRedirects;
                this.path = url;
                // let path = url.split('/');
                // path = path[1].split('?');
                // if (path && path.length) {
                //     this.path = '/' + path[0];
                // }
                // else {
                //     this.path = event.url;
                // }
            }
            if ((event.url === '/') || (event.url === '/home')) {
                this.showFacilitySelector = true;
            } else if (event.url === '/administration/facility') {
                this.showFacilitySelector = true;
            } else if (event.url === '/administration/setupsubscription') {
                this.showFacilitySelector = true;
            }
            else {
                this.showFacilitySelector = false;
                this.disableNotificationIconForOtherPages = true;
            }
        });

        this.selfServiceDisbaleSub = this.commonService.selfServiceDisable
            .subscribe(isChange => {
                if (isChange) {
                    this.unauthorized = false;
                    this.selfService = false;
                    this.appLoad = true;
                    this.popupMaxInterval(isChange);
                }
            });
    }

    loadApp() {
        let allSecuredObjects: any;
        allSecuredObjects = this.serverlessOauthService.getOauthProfile();
        const existingToken = this.cookieService.get('host-token');
        if (!existingToken && !(allSecuredObjects && allSecuredObjects.token) && (window.location.href.indexOf('token') === -1 || window.location.href.indexOf('selfService') > -1)) {
            this.selfService = true;
            this.appLoad = false;
            this.unauthorized = false;
        }
        else {
            this.selfService = false;
            this.appLoad = true;
            this.unauthorized = false;
            this.blockUI.stop();
            if (allSecuredObjects && (allSecuredObjects.token || existingToken)) {
                this.blockUI.start();
                if (!existingToken && allSecuredObjects.token) {
                    const myDate: Date = new Date();
                    myDate.setHours(myDate.getHours() + 6);
                    this.cookieService.set('host-token',
                        allSecuredObjects.token,
                        {
                            expires: new Date(new Date().getTime() + 1000 * 60 * 30),
                            path: '/',
                            secure: true,
                            sameSite: 'Strict'
                        }
                    );
                    this.commonService.oAuthToken = allSecuredObjects.token;
                }
                else if (existingToken && existingToken !== allSecuredObjects.token) {
                    this.commonService.oAuthToken = allSecuredObjects.token;
                }
                else {
                    this.commonService.oAuthToken = existingToken
                }

                this._cleanUrl();
                this.showRouter = true;

                this.commonService.getRbacProfileCustom().subscribe({
                    next: (res: any) => {
                        if (res && res.data) {
                            this.customRbacService.rbacCustomObj = res.data;                           
                            let username = this.customRbacService.rbacCustomObj?.user_name;
                            this.commonService.getFacilitiesList(username).subscribe({
                                next: (res: facilityListAPIResponse) => {
                                    if ((res && res.data && res.data.length > 0) || (this.customRbacService.rbacCustomObj?.current_role && this.customRbacService.rbacCustomObj.current_role.role_name === 'System Administrator')) {                                      
                                        if (res && res.data && res.data.length > 0) { this.facilityList = res.data; }
                                        else { this.facilityList = []; }
                                        this.blockUI.stop();
                                        this.loadWithRBAC = true;
                                        this.handleFirstTimeNavigation(this.path);
                                        this.trackPageNavigation();
                                        this.modifyMenu();
                                        this.router.navigate([this.path]);
                                        if (this.path && this.path.indexOf('preview') > -1) {
                                            this.changeHeaderForPreview();
                                        }

                                    } else {
                                        this.popupMaxInterval(false);
                                        this.blockUI.stop();
                                        this.toastrService.error('Error: Logged user does not mapped with valid Facility;');
                                    }
                                },
                                error: (error: any) => {
                                    console.error(error);
                                    this.popupMaxInterval(false);
                                    this.blockUI.stop();
                                    this.toastrService.error('Error: Logged user does not mapped with valid Facility;');
                                }
                            })
                        } else {
                            this.popupMaxInterval(false);
                            this.blockUI.stop();
                            this.toastrService.error('Error: User dont have valid Adams Assignments.');
                        }
                    },
                    error: (error: any) => {
                        console.error(error);
                        this.popupMaxInterval(false);
                        this.blockUI.stop();
                        this.toastrService.error('Error occured with ADAMS.Please connect with Administrator.');
                    }
                });
            }
            else {
                this.toastrService.error('Error: User dont have valid Login.Please connect with Administrator.');
                this.popupMaxInterval(false);
            }
        }
    }

    approvalNotification() {
        let approveModal = this.modalService.open(CommonModalComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'md'
        });

        approveModal.componentInstance.title = 'Pending Approvals';
        approveModal.componentInstance.bodyText = 'You have pending facility registrations awaiting your approval.';
        approveModal.componentInstance.okText = 'Ok';
        approveModal.componentInstance.showCancel = false;
    }

    getBadgeCount() {
        this.myDietCommonService.getBadgeCount().subscribe({
            next: (res) => {
                if (res && res.data) {
                    this.badgeCount = res.data;
                    this.hidden = false;
                }
            },
            error: (error) => {
                console.error(error);
                this.toastrService.error('Error: Could not get pending approval records count.');
            }
        });
    }

    showNotificationCount() {
        if (this.badgeCount > 0) {
            this.approvalNotification();
        }
    }

    ngOnDestroy(): void {
        if (this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval);
        }
        if (this.selfServiceDisbaleSub) {
            this.selfServiceDisbaleSub.unsubscribe();
        }
    }

    private changeHeaderForPreview() {
        if (this.pageHeader) {
            let pageHeading: any = this.pageHeader;
            pageHeading.title = "Diet Manual Preview";
        }
    }

    private loadUnauthorizationPopup(showPopup: any) {
        if (showPopup) {
            let modal = this.modalService.open(CommonModalComponent, {
                keyboard: false,
                backdrop: 'static'
            });
            modal.componentInstance.title = 'Unauthorized';
            modal.componentInstance.bodyText = 'You are not authorized in (MDM)-MyDietManual.';
            modal.componentInstance.okText = 'OK';
            modal.componentInstance.showCancel = false;
            return modal.result.then(() => {
                this.navigateToSelfService();
                return true;
            }).catch(() => {
                this.navigateToSelfService();
                return false;
            });
        } else {
            this.navigateToSelfService();
            return false;
        }
    }

    private navigateToSelfService() {
        this.unauthorized = false;
        this.selfService = true;
        this.appLoad = true;
        this.router.navigate(['/selfService']);
    }

    private popupMaxInterval(reqForLogin: any) {
        clearInterval(this.autoSaveInterval);
        if (!reqForLogin) {
            this.unauthorized = true;
            this.appLoad = false;
            this.cookieService.delete('host-token');
            this.showPopup = true;
            this.router.navigate(['/unauthorized']);
            this.loadUnauthorizationPopup(this.showPopup);
        }
        else {
            this.autoSaveInterval = setInterval(() => {
                this.hidePopup();
            }, 200);
        }

    }

    private hidePopup() {
        if (this.timerLeft > 0) {
            this.timerLeft--;
            if (this.timerLeft === 49) {
                this.showAuthMsg = true;
            }
        } else {
            this.appLoad = false;
            this.timerLeft = 30;
            this.showAuthMsg = false;
            this.showRouter = true;
            this.showPopup = false;
            clearInterval(this.autoSaveInterval);
            this.showAuthMsg = false;
            this.popupMaxInterval(false);
        }
    }

    private trackPageNavigation() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if ((event.url === '/') || (event.url === '/home')) {
                    this.showFacilitySelector = true;
                    if (this.customRbacService.rbacCustomObj?.current_role?.role_name === "System Administrator") {
                        this.disableNotificationIconForOtherPages = false;
                        this.getBadgeCount();
                    }
                } else if (event.url === '/administration/facility') {
                    this.showFacilitySelector = true;
                } else if (event.url === '/administration/setupsubscription') {
                    this.showFacilitySelector = true;
                }
                else {
                    this.showFacilitySelector = false;
                    this.disableNotificationIconForOtherPages = true;
                }
            }
        });
    }

    private handleFirstTimeNavigation(path: string) {
        if ((path === '/') || (path === '/home')) {
            this.showFacilitySelector = true;
            if (this.customRbacService.rbacCustomObj?.current_role?.role_name === "System Administrator") {
                this.disableNotificationIconForOtherPages = false;
                this.getBadgeCount();
            }
        } else {
            this.disableNotificationIconForOtherPages = true;
        }
    }

    private _cleanUrl(): void {
        const href = location.origin + location.pathname
            + location.search.replace(/&?token=[^&\$]*/, '')
                .replace(/&$/, '')
                .replace(/^\?$/, '')
                .replace(/&+/g, '&')
                .replace(/\?&/, '?')
                .replace(/\?$/, '')
            + location.hash;
        history.replaceState(null, window.name, href);
    }

    private modifyMenu() {
        if (this.customRbacService.rbacCustomObj?.secured_objects && this.customRbacService.rbacCustomObj?.secured_objects.length > 0) {
            let securedObj: any = this.customRbacService.rbacCustomObj?.secured_objects?.map(x => x.object_name);
            let roleBasedMenu: any = [];
            this.menuItemsList.forEach((element: any) => {
                if (!element.stgSecuredObjectCustom) {
                    roleBasedMenu.push(element);
                }
                else if (securedObj?.indexOf(element.stgSecuredObjectCustom) > - 1) {
                    roleBasedMenu.push(element);
                }
            });

            this.menuItems = roleBasedMenu;
            this.showMenu = true;
        }

        else {
            this.toastrService.error("Error : User access denied");
            setTimeout(() => {
                window.location.href = this.appConfigService.getConfig().api.uri + "/selfService";
            }, 600);
        }
    }
}