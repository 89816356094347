import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-image-icon',
  templateUrl: './custom-image-icon.component.html',
  styleUrls: ['./custom-image-icon.component.scss']
})
export class CustomImageIconComponent implements ICellRendererAngularComp {
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;

    this.params.value = this.params.value ? this.params.value : '/assets/images/placeholder.png';
  }
  
  refresh(): boolean {
    return true;
  }

}
