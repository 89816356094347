import { Component, Input, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-right-content',
  templateUrl: './right-content.component.html',
  styleUrls: ['./right-content.component.scss']

})
export class RightContentComponent implements OnChanges {
  @Input() contentId: string;
  @Input() manualId: string;
  @Input() selectedSubMenu: string;
  @Input() selectedMenu: string;
  @Input() searchText: string;
  content: SafeHtml;
  printContentVar: any;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private toastrService: ToastrService,
    private commonService: MyDietCommonService,
    private sanitizer: DomSanitizer) { }

  ngOnChanges(): void {
    if (this.contentId) {
      this.getToC(this.contentId);
    }
  }

  printContent(): void {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.printContentVar as string;
    const wordSection2 = tempDiv.querySelector('.WordSection2');
    if (wordSection2) {
      wordSection2.remove();
    }

    const pageBreakSpans = tempDiv.querySelectorAll('span');
    pageBreakSpans.forEach(span => {
      const br = span.querySelector('br[style*="page-break-before:always"]');
      if (br) {
        span.remove();
      }
    });

    const iframe: any = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);
    const printDocument = iframe.contentWindow.document;
    printDocument.open();
    printDocument.write(`
        <html>
            <head>
                <title>Diet Manual - Nutrition Manual Across the Continuum of Care</title>
                <style>
                  body {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                  }
                  .section-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                    border-bottom: 1px solid #ccc;
                  }
                  .toc-submenu-name {
                    padding: 10px;
                    background-color: #f3f3f3;
                    border: 1px solid #ccc;
                  }
                  .content {
                    padding: 10px;
                    color: black !important;
                  }
                  h1 {
                    margin-top: .85pt !important;
                    margin-right: 0in !important;
                    margin-bottom: 0in !important;
                    margin-left: 5.85pt !important;
                    margin-bottom: .0001pt !important;
                    font-size: 16.0pt !important;
                    text-align: center;
                  }
                  h1 a {
                    font-weight: bold !important;
                    color: #333 !important;
                  }
                  h2 {
                    font-size: 10.0pt !important;
                    font-family: "Cambria", serif !important;
                    font-weight: bold !important;
                    color: #333 !important;
                  }
                  table {
                    width: 80% !important;
                    border-collapse: collapse;
                  }
                  th, td {
                    border: 1px solid #ccc;
                    padding: 8px;
                    text-align: left;
                  }
                   @media print {
                    body {
                      margin: 0;
                      padding: 0;
                      box-sizing: border-box;
                      width: 100%;
                      height: 100%;
                    }
                    .page-break {
                      page-break-before: always;
                    }
                    .section-header, .toc-submenu-name, .content, h1, h2 {
                      page-break-inside: avoid;
                    }
                      br[style*='page-break-before:always'] {
                      display: none !important;
                    }
                    .WordSection2 {
                      margin: 0 !important;
                      padding: 0 !important;
                    }
                  }
                </style>
            </head>
            <body>
          
               ${tempDiv.innerHTML}
            </body>
        </html>
        `);
    printDocument.close();
    iframe.onload = () => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    };
  }

  private getToC(tocId: string): void {
    this.blockUI.start('Loading...');
    this.commonService.getSelectedToC(this.manualId, tocId).subscribe((response: any) => {
      this.blobToString(response).then(htmlString => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(htmlString);
        this.printContentVar = htmlString;
      });
      this.blockUI.stop();
    },
      (error: any) => {
        this.blockUI.stop();
        this.toastrService.error('Error while fetching the Table of Content.');
      })
  }

  private blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let result = reader.result as string;
        result = result.replace(/color\s*:\s*[^;]+';\s*/gi, '');
        result = result.replace(/"Aptos Display"/gi, '"Calibri"');
        if (this.searchText && this.searchText.toString().trim()) {
          const regex = new RegExp(`(${this.searchText.toString().trim()})`, 'gi');
          result = result.replace(regex, '<mark style="background-color:#efff00">$1</mark>');
        }
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }
}