import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss']
})
export class CustomEmailComponent implements ICellRendererAngularComp {
  params: any;
  mailUrl:any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }
  
  refresh(): boolean {
    return true;
  }


}
