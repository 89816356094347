import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news-display-modal',
  templateUrl: './news-display-modal.component.html',
  styleUrls: ['./news-display-modal.component.scss']
})
export class NewsDisplayModalComponent implements OnInit {

  @Input() title: string = 'Confirmation';
  @Input() bodyTitle: string;
  @Input() bodyText: string;
  @Input() cancelText: string = 'Close';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
}