import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManualsService } from '../../manuals.service';
import { ALLOWED_FILE_FORMATS } from 'src/app/common/mydiet-constants';


@Component({
  selector: 'app-add-document-modal',
  templateUrl: './add-document-modal.component.html',
  styleUrls: ['./add-document-modal.component.scss']
})
export class AddDocumentModalComponent implements OnInit {

  @Input() selectedFacility: string | number;
  @Input() selectedFacilityId: string | number;
  @Input() processMode: string;
  @Input() modifiedBy: string;
  @Input() displayName: string;
  @Input() displayOrder: string;
  @Input() id: Number;
  @Input() existingDisplayName: string[] = [];
  @Input() existingFileName: string[] = [];

  public title: string;
  public fileName: string;
  public fileUploadedName: string;
  public displayOrderError: boolean = false;
  public displayNameExistError: boolean = false;
  public fileFormatError: boolean = false;
  public displayNameError: boolean = false;
  public isEdit: boolean = false;
  public displayErrMsg: string = 'Display Order value is not compatible.';
  public fileNameExistError: boolean = false;
  public fileSizeError: boolean = false;
  public fileSizeZeroError: boolean = false;

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('myFileUpload') myFileUpload: ElementRef;

  constructor(private manualService: ManualsService, private toastrService: ToastrService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.processMode === 'new') {
      this.title = "Add Diet Document"
    }
    else {
      this.isEdit = true;
      this.fileUploadedName = this.displayName;
      this.title = "Update Diet Document"
    }
  }

  validateDisplayName() {
    if (this.displayName && this.displayName.trim()) {
      this.displayNameError = false;
      if (this.existingDisplayName && this.existingDisplayName.length > 0 && this.existingDisplayName.indexOf(this.displayName.trim().toUpperCase()) > -1) {
        this.displayNameExistError = true;
      }
      else {
        this.displayNameExistError = false;
      }
      this.displayName = this.displayName.trim();
    }
    else {
      this.displayNameError = true;
    }
  }

  validateDisplayOrder() {
    if (!(/^\d*\.?\d*$/.test(this.displayOrder))) {
      this.displayErrMsg = 'Display Order should be numeric.'
      this.displayOrderError = true;
    }
    else if (this.displayOrder && !Number.isInteger(Number(this.displayOrder))) {
      this.displayErrMsg = 'Display Order should be whole number and less than 30000.'
      this.displayOrderError = true;
    }
    else if (this.displayOrder && Number(this.displayOrder) <= 0) {
      this.displayErrMsg = 'Display Order should be more than 0.'
      this.displayOrderError = true;
    }
    else if (this.displayOrder && Number(this.displayOrder) > 30000) {
      this.displayErrMsg = 'Display Order should be less than 30000.'
      this.displayOrderError = true;
    }
    else {
      this.displayOrderError = false;;
    }
  }

  saveDocument() {
    if (!this.isEdit) {
      if (this.myFileUpload.nativeElement.files && this.myFileUpload.nativeElement.files[0]) {
        let validDocFile = true;
        this.fileFormatError = false;
        this.fileNameExistError = false;
        this.fileSizeError = false;
        this.fileSizeZeroError = false;
        const file: File = this.myFileUpload.nativeElement.files[0];
        if (file.size === 0) {
          this.fileSizeZeroError = true;
          return;
        }
        if (file.size > 16777216) {
          this.fileSizeError = true;
          return;
        }
        if (file.type && ALLOWED_FILE_FORMATS.indexOf(file.type) === -1) {
          this.fileFormatError = true;
          return;
        }
        if (this.existingFileName.indexOf(this.fileUploadedName.toUpperCase()) > -1) {
          validDocFile = false;
        }

        if (!validDocFile) {
          this.fileNameExistError = true;
          return;
        }
        this.fileName = file.name;
        const formData = new FormData();
        formData.append('File', file);
        formData.append('FacilityId', this.selectedFacilityId.toString());
        formData.append('CostCenter', this.selectedFacility.toString());
        formData.append('DisplayName', this.displayName.trim());
        formData.append('Order', this.displayOrder);
        formData.append('ModifiedBy', this.modifiedBy);

        this.blockUI.start();
        this.manualService.uploadAddendum(formData).subscribe({
          next: (res) => {
            if (res.metaData && res.metaData.status === "Success") {
              this.toastrService.success('Diet Document Uploaded Successfully.');
              this.activeModal.close('success');
            } else {
              this.toastrService.error('Could not Upload Diet Document.');
            }
            this.blockUI.stop();
          },
          error: (error) => {
            console.error(error);
            this.blockUI.stop();
            this.toastrService.error('Could not Upload Diet Document.');
          }
        });
      }
    } else {
      this.blockUI.start();
      this.manualService.updateFacilitySpecificDocument(this.id, Number(this.selectedFacilityId), this.displayName.trim(),
        Number(this.displayOrder), this.modifiedBy
      ).subscribe({
        next: (res) => {
          if (res.metaData && res.metaData.status === "Success") {
            this.toastrService.success('Diet Document Updated Successfully.');
            this.activeModal.close('success');
          } else {
            this.toastrService.error('Could not Update Diet Document.');
          }
          this.blockUI.stop();
        },
        error: (error) => {
          console.error(error);
          this.blockUI.stop();
          this.toastrService.error('Could not Update Diet Document.');
        }
      });
    }
  }

}