export const environment = {
    production: false,
    urls: {
        rbacProfile: '/api/users/profile?app_name={app_name}',
        rbacProfileMyDiet: '/api/user/GetCustomRbacProfile',
        userRegistration: '/api/Registration/UserRegistration',
        getAllApproval: '/api/Approval/GetAllApproval',
        rejectApprovalData: '/api/Approval/Reject',
        approveUserData: '/api/Approval/Approve',
        getBadgeCount: '/api/Approval/ApprovalPending',
        updateNewCostCenter: '/api/Approval/UpdateCostCenter',
        getSubscriptionInformation: '/api/Subscription/GetSubscriptionByFacilityNumber?costCenter={costCenter}',
        // getValidFacilitySubscription: '/api/Subscription/GetValidFacilitySubscription?userName={userName}',
        getFacilitiesList: '/api/Facility/GetUserRoleCostCenters?userName={userName}',
        postFacilitySpecificDocument: '/api/Facility/UploadDocument',
        getFacilitySpecificDocumentList: '/api/Facility/GetAllFacilityFiles?FacilityId={FacilityId}',
        downloadFacilitySpecificDocument: '/api/Facility/DownloadDocument?FacilityFileId={FacilityFileId}&CostCenter={CostCenter}',
        getAllSystemNews: '/api/SystemNews/GetAllSystemNews',
        getAllFacilityNews: '/api/Facility/GetAllFacilityNews?facilityId={facilityId}',
        updateFacilitySpecificDocument: '/api/Facility/UpdateFacilityFile?FacilityFileId={FacilityFileId}&FacilityId={FacilityId}&DisplayOrder={DisplayOrder}&ModifiedBy={ModifiedBy}&DisplayName={DisplayName}',
        deleteFacilitySpecificDocument: '/api/Facility/DeleteFacilityFile?FacilityFileId={FacilityFileId}&CostCenter={CostCenter}',
        getAllSubscriptions: '/api/Subscription/GetAllSubscriptions',
        insertSubscriptionTemplateDetails: '/api/Subscription/AddSubscription',
        updateSubscriptionTemplateDetails: '/api/Subscription/EditSubscription',
        deleteSubscriptionTemplateDetails: '/api/Subscription/DeleteSubscription?Id={Id}',
        getFacilityAndSubscriptionByFacilityId: '/api/Facility/GetFacilityAndSubscriptionByFacilityId?id={id}',
        getUsersByFacilityAndSubscription: '/api/User/GetUsersByFacilityAndSubscription?facilityId={facilityId}',
        addUserToFacilitySubscription: '/api/User/AddFacilitySecUser',
        editUserToFacilitySubscription: '/api/User/EditFacilitySecUser',
        deleteUserToFacilitySubscription: '/api/User/DeleteFacilitySecUser',
        SystemNews: '/api/SystemNews/SystemNews',
        deleteSystemNews: '/api/SystemNews/DeleteSystemNews?SystemNewsId={SystemNewsId}',
        FacilityNews: '/api/Facility/FacilityNews',
        deleteFacilityNews: '/api/Facility/DeleteFacilityNews?FaciityNewsId={FaciityNewsId}',
        addPatientEducationGroupComponent: '/api/PatientDocumentGroup',
        getAllPatientEducationLanguages: '/api/PatientDocumentGroup/GetAllPatientEducationLanguages',
        getAllPatientEducationDocumentGroups: '/api/PatientDocumentGroup',
        deletePatientEducationDocGroup: '/api/PatientDocumentGroup?Id={Id}',
        updatePatientEducationGroupComponent: '/api/PatientDocumentGroup',
        crudManual: '/api/Manual',
        downloadManualSection: '/api/ManualDocument/DownloadFile',
        updateManualStatus: '/api/Manual/{id}/{status}/{manualType}',
        deleteManual: '/api/Manual/{id}',
        aaimsResetUser: '/api/User/ResetFacilitySecUser',
        crudSection: '/api/ManualDocument',
        getSectionsByManualId: '/api/ManualDocument/GetManualDocumentsByManualId',
        crudPatientEducationDocument: '/api/PatientDocument',
        getPatientHandout: '/api/PatientDocument/HandOutDownload',
        getPatientEducationDocumentIFrame: '/api/PatientDocument/Download',
        getManualToc: '/api/ManualDocument/GetManualToc?manualId={manualId}&manualTocId={manualTocId}',
        getActiveManual: '/api/Manual/GetActiveManual',
        getManualMenus: '/api/ManualDocument/ManualDocumentTocDetails?manualId={manualId}',
        getManualDocumentSearchToC: '/api/ManualDocument/ManualTocSearch',
        paymentIntegration: '/api/Payment/ProcessPayment',
        paymentSummary: '/api/Payment/SubscriptionSummary',
        getfacilityListTable: '/api/SubscriptionFacility/GetAllFacilitiesByUsersAndSubscriptions/{UserName}',
        updateSubscription: '/api/Facility/UpsertSubscription?facilityId={facilityId}&status={subscriptionStatus}',
        updateBedCountAndTier:'/api/SubscriptionFacility/FacilitiesPatchBedCountTier',
        validateCostCenter: '/api/Facility/validateCostCenter',
        additionalFacilityRegistration: '/api/Registration/AdditionalFacilityRegistration'
    }
};
