<div class="modal-header" style="background-color: #70c1c0; font-weight: 600;">
    <h4 class="custom-title">{{title}}</h4>
    <button mat-icon-button type="button" aria-label="Close" (click)="activeModal.dismiss(null)"
        style="border: none; background:transparent;margin:auto; right: 20px; position: absolute;">
        <i class="fas fa-times"></i>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 custom-input">
            <h5> Display Name<span>*</span></h5>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="row">
                <div class="input-group mb-3">
                    <input type="text" (change)="validateDisplayName()" maxlength="35" class="form-control"
                        [(ngModel)]="displayName" placeholder="Display Name" aria-describedby="basic-addon2">
                </div>
                <div> <span *ngIf="displayNameExistError" class="row errorSpan">*Given display name already
                        exist.</span> </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 custom-input">
            <h5> Display Order<span>*</span></h5>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="row">
                <div class="input-group mb-3">
                    <input type="text" (change)="validateDisplayOrder()" myDietOnlyNumbers class="form-control"
                        [(ngModel)]="displayOrder" placeholder="Display Order" aria-describedby="basic-addon2">
                </div>
                <div> <span *ngIf="displayOrderError" class="row errorSpan">{{displayErrMsg}}</span> </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 custom-input">
            <h5> File<span>*</span></h5>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div *ngIf="!isEdit">
                <div class="file-container">
                    <input id="myFileUploadId" #myFileUpload style="display:none;" type="file" accept=".pdf"
                        (change)="fileUploadedName = myFileUpload.value.substring(myFileUpload.value.lastIndexOf('\\') + 1);">
                    <mat-form-field appearance="fill" class="upload-input">
                        <mat-label>File</mat-label>
                        <input id="lblFileName" [(ngModel)]="fileUploadedName" class="form-control" matInput disabled
                            required>
                    </mat-form-field>
                    <button class="btn btn-primary choose-file" (click)="myFileUpload.click();">Choose
                        File</button>
                </div>
                <span *ngIf="fileFormatError" class="row errorSpan">*Allowed formats : pdf only</span>
                <span *ngIf="fileNameExistError" class="row errorSpan">*A file with this name already exits. Please
                    upload a file with different file name.</span>
                <span *ngIf="fileSizeError" class="row errorSpan">*Given file size is more than allowed limit
                    16MB.</span>
                <span *ngIf="fileSizeZeroError" class="row errorSpan">*Given file is empty.</span>
            </div>
            <div *ngIf="isEdit">
                <span style="color: #70c1c0; font-weight: 600; cursor:pointer">{{fileName}}</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Cancel</button>&nbsp;&nbsp;
    <button type="button" class="btn btn-primary"
        [disabled]="!fileUploadedName || !displayOrder || displayNameError || displayNameExistError ||!displayName || (displayName && displayName.trim().length === 0) || displayOrderError"
        (click)="saveDocument()">Save</button>
</div>