import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManualsService } from 'src/app/manuals/manuals.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addendums-preview',
  templateUrl: './addendums-preview.component.html',
  styleUrls: ['./addendums-preview.component.scss']
})
export class AddendumsPreviewComponent implements OnInit {
title:any ="Facility Specific Diet Preview";
  @Input() selectedFacility:string;
  @Input() dietId:any;
  routeSubscription: Subscription;
  @ViewChild('pdfIframe') pdfIframe: ElementRef;
  @BlockUI() blockUI: NgBlockUI;

  constructor(private manualService: ManualsService,
    private toastrService: ToastrService, 
  public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.printContent();
    // this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
    //   if (params.has('dietId') && params.has('costCenter')) {
    //     let queryParamdietId = Number(params.get('dietId')) ? Number(params.get('dietId')) : "0";
    //     let queryParamCostCenter = Number(params.get('costCenter')) ? Number(params.get('costCenter')) : "0";
    //     if (queryParamdietId === 0 || queryParamCostCenter === 0) {
    //       this.toastrService.error('URL does not have valid diet document data.');
    //       this.router.navigate(['/home']);
    //     }
    //     else {
    //       this.printContent(Number(queryParamdietId), queryParamCostCenter);
    //       this.titleService.setTitle("Preview Facility Specific Diet");
    //     }
    //   } else {
    //     this.toastrService.error('URL does not have valid diet document data.');
    //     this.router.navigate(['/home']);
    //   }
    // });
  }

//  printContent(dietId: number, selectedFacility: any): void 
  printContent(): void{
    this.blockUI.start();
    this.manualService.previewFacilitySpecificDocument(this.dietId, this.selectedFacility).subscribe({
      next: (response) => { 
        this.blockUI.stop();
        var blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob) + "#toolbar=0&navpanes=0&scrollbar=1";
        const iframe = this.pdfIframe.nativeElement as HTMLIFrameElement;
        iframe.style.display = 'block';
        iframe.src = blobUrl;       
      },
      error: (error) => {
        console.error(error);
        this.blockUI.stop();      
        this.toastrService.error('Error occurred while loading document preview.');
        this.activeModal.close()
      }
    });
  }
}